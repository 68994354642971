<template>
    <div class="about">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
       
    },
}
</script>
<style lang="scss" scoped>
    .about{
        .menuList{
            width: 100%;
            margin: auto;
            display: flex;
            border-bottom: solid 1px #ccc;
            .menuItem{
                padding: 10px 20px;
                cursor: pointer;
            }
            .menuItem.active{
                background:#01419A;
                color:#fff;
            }
        }
    }
</style>