<template>
  <!-- 行业现状模块 -->
  <div class="videoions" style="padding:30px">
    <div class="message">
      <label class="borders"></label>
      行业现状
      <label class="borders"></label>
    </div>
    <div class="videoalls" v-if="flag == 2 || flag == 3">
      <div v-for='(item, index) in flagone[flag].imglists' :key='index' style="width:31%;">
        <img :src="require('../assets/indust/' + item.url)" style='width:100%;' />
        <div>
          <div class="itits" :style="flag == 2 ? ' height: 60px;' : ''">{{ item.tit }}</div>
          <div class="itxt" style="color:#747575;text-align:center;">{{ item.txt }}</div>
        </div>
      </div>
    </div>

    <div class="videoall" v-else-if="flag == 0">
      <div v-for='(item, index) in listall[dex].imglists' :key='index' style="width:388px;">
        <div v-if="index % 2 === 1">
          <div class="boxtxt">
            <div class="itit" style="color:#291C37">{{ item.tit }}</div>
            <div class="itxt" style="color:#747575">{{ item.txt }}</div>
          </div>
          <img style='width:100%;height: 220px;' :src="require('../assets/indust/' + item.url)" class='imgup' />
        </div>
        <div v-else style="position:relative;bottom:20px;">
          <img style='width:100%;height: 220px;' :src="require('../assets/indust/' + item.url)" class='imgups' />
          <div class="boxtxt">
            <div class="itit" style="color:#291C37">{{ item.tit }}</div>
            <div class="itxt" style="color:#747575">{{ item.txt }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="videoall" v-else>
      <div v-for='(item, index) in flagone[flag].imglists' :key='index' style="width:388px;">
        <div v-if="index % 2 === 1">
          <div class="boxtxt">
            <div class="itit" style="color:#291C37">{{ item.tit }}</div>
            <div class="itxt" style="color:#747575">{{ item.txt }}</div>
          </div>
          <img style='width:100%;height: 220px;' :src="require('../assets/indust/' + item.url)" class='imgup' />
        </div>
        <div v-else style="position:relative;bottom:20px;">
          <img style='width:100%;height: 220px;' :src="require('../assets/indust/' + item.url)" class='imgups' />
          <div class="boxtxt">
            <div class="itit" style="color:#291C37">{{ item.tit }}</div>
            <div class="itxt" style="color:#747575">{{ item.txt }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Industry",
  // 接受父组件的值
  props: {
    flag: Number,
    dex: Number,
    required: true
  },
  data() {
    return {
      listall: [
        {
          imglists: [//行业现状
            { url: "m1.png", tit: '防盗追车破案难', txt: '电动车防盗能力弱，销赃猖獗；每天与电动自行车有关的治安报警量占数量比例高，严重浪费警力等社会资源。' },
            { url: "m2.png", tit: '上牌登记备案难', txt: '电动车没有车辆识别信息，无法识别被盗车辆和非被盗车辆；查没车辆无法找到失主，使警民双方承受双重困扰。' },
            { url: "m3.png", tit: '安全行驶监管难', txt: ' 车主无驾驶证，车辆未登记上牌，因此即使驾驶行为不规范，无法有效监控跟踪。' },
            { url: "m4.png", tit: '火灾隐患预防难', txt: '电动自行车引起的火灾事故时有发生，已成为重点整治项目；无法统一配套安全合规的充电设施，容易引发充电火灾，造成更大安全隐患.' },
          ]
        },
        {
          imglists: [//行业现状
            { url: "m11.png", tit: '诱发违停违章 导致交通堵塞的问题 ', txt: '车辆集中降低交通效率，导致交通拥堵与汽车高排放。 同时易诱发违停违章，进一步造成城市道路堵塞，易带来交通安全风险。' },
            { url: "m13.png", tit: '停车收费混乱 服务成空白 ', txt: '由于管理配套不齐全，主管部门对停车位的收费、规划和 调节等服务难以实现。大量无资质的停车场被周边单位和个人控制，导致一些停车场收费混乱。' },
            { url: "m12.png", tit: '车位信息不对称 寻找停车位难', txt: ' 由于缺乏统一的管控手段，目前部分城市停车位信息无法共享，停车最后一公里耗时耗油，导致民众自驾出行不便，降低城市生活幸福感。' },
            { url: "m14.png", tit: '监管无支撑 难以杜绝违停现象', txt: '停车静态数据无法汇聚到统一平台，难以统一管理。同时停车管理缺少工具，传统巡查方式消耗警力，无法真正杜绝违停现象。.' },
          ]
        },
        {
          imglists: [//行业现状
            { url: "m1.png", tit: '车辆管理困难', txt: '车辆使用过程中，管理手段单一，车辆调度困难，车辆利用率太低，大量资源闲置。' },
            { url: "m2.png", tit: '维保费用过多', txt: '数量过多维保繁杂，车辆运行成本不可控，各种费用与实际不相符。' },
            { url: "m3.png", tit: '监督存在盲点', txt: ' 公车私用无法追溯、车辆违章危险驾驶、车辆去向不明及行驶路线无法掌握。' },
            { url: "m4.png", tit: '政策改革需求', txt: '地方公务用车制度改革规定，防止公车私用，公车经费支出居高不下。' },
          ]
        },
      ],
      flagone: [
        {
          imglists: [//行业现状
            { url: "m1.png", tit: '防盗追车破案难', txt: '电动车防盗能力弱，销赃猖獗；每天与电动自行车有关的治安报警量占数量比例高，严重浪费警力等社会资源。' },
            { url: "m2.png", tit: '上牌登记备案难', txt: '电动车没有车辆识别信息，无法识别被盗车辆和非被盗车辆；查没车辆无法找到失主，使警民双方承受双重困扰。' },
            { url: "m3.png", tit: '安全行驶监管难', txt: ' 车主无驾驶证，车辆未登记上牌，因此即使驾驶行为不规范，无法有效监控跟踪。' },
            { url: "m4.png", tit: '火灾隐患预防难', txt: '电动自行车引起的火灾事故时有发生，已成为重点整治项目；无法统一配套安全合规的充电设施，容易引发充电火灾，造成更大安全隐患.' },
          ]
        },
        {
          imglists: [//行业现状
            { url: "m11.png", tit: '电动自行车管理难', txt: '电动自行车车辆多，缺乏公共充电设备，违规充电屡禁不止，存在堵塞通道、飞线充电、楼道内或室内充电等违规行为，易引发消防事故，居民安全难保障。' },
            { url: "m13.png", tit: '社会热议问题', txt: '防疫、高空抛物、垃圾分类等成为社会热议话题，也是目前各个社区亟待解决的问题，但传统社区缺乏这类管理手段，传统管理手段难以触及此类难题。' },
            { url: "m12.png", tit: '社区安全', txt: ' 每日社区访客、外来人员及车辆多，识别困难且管理成本高，造成社区治安隐患逐渐突出，影响居民安全感、幸福感。' },
            { url: "m14.png", tit: '消防问题', txt: '传统社区消防设备滞后，居民消防意识淡薄，一旦发生意外后果严重。' },
          ]
        },
        {
          imglists: [//行业现状
            { url: "s1.png", tit: '消防信息智能化低  信息分散', txt: '传统消防设备信息分散，无法联动。再加上由于消防水泵长期静置，经常发生需要时消防水泵不能正常启用的情况，一旦失火，消防水泵无法或不能及时启动。' },
            { url: "s2.png", tit: '上牌消防日常巡检工作量大  安全管理人员不足登记备案难', txt: '基层消防工作量大，且面临着面广、线长、分散等巡检情况，不论是在人力还是监管手段上，都很难做到全面覆盖，容易出现监管漏洞。' },
            { url: "s3.png", tit: '“九小”场所难以监管  应急预案不完善', txt: ' 随着社会经济快速发展，“九小”场所数量剧增，一般监督检查难以触及。再加上传统消防数据采集不全，无法形成完善的应急预案。' },
          ]
        },
        {
          imglists: [//行业现状
            { url: "s11.png", tit: '养殖散户', txt: '资金有限，无法扩大规模；贷款门槛高；出栏率低，市场供不应求' },
            { url: "s12.png", tit: '金融机构', txt: '活体资产确权难、抵押难，机构不敢贷、不愿贷、不能贷；业务未触达精准人群；贷款无担保' },
            { url: "s13.png", tit: '担保公司', txt: '担保有风险；无法有力监督担保资产；融资效率低' },
          ]
        },
        {
          imglists: [//行业现状
            { url: "m21.png", tit: '企业资产众多  管理手段落后', txt: '部分资产密集型企业固定资产数量众多，依然采用人工出入库登记管理，不仅浪费人力，而且存在误差率高，权责不清，管理混乱的情况' },
            { url: "m222.png", tit: '单人固定资产价值高 丢失风险高', txt: '企业内尤其研发类部门的人员，单人资产配置价值较高，无有效监管手段，丢失后对于个人及企业均会造成严重影响' },
            { url: "m23.png", tit: '资产转移率高 传统监管手段数据易出错', txt: ' 企业内公共固定资产借用流程复杂，借用后卫及时归还会导致资产隐形闲置，资产利用率低，急需优化' },
            { url: "m24.png", tit: '火灾隐患预防难', txt: '部分企业人员流动性大，资产转移率高，需要更为可靠的资产流程控制能力，才能保证运营数据的准确性.' },
          ]
        },
      ],
    };
  },
  methods: {
  }
};
</script>
<style scoped>
.videoions {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
}

.message {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #121212;
  margin: 60px 0;
}

.borders {
  border-bottom: 1px solid #666666;
  width: 200px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}

.itxt {
  font-size: 16px;
  padding: 0 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 22px;
  text-align: left;
}

.itit {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  padding: 0px 30px 10px 30px;
  line-height: 30px;
  color: #FFFFFF;
}

.itits {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 10px;
  color: #291C37;
}

.boxtxt {
  height: 220px;
  padding-top: 20px;
  box-sizing: border-box;
  background: #FFFFFF;
  z-index: 99;
  box-shadow: 0px 8px 9px 1px rgba(21, 39, 72, 0.1800);
}

.imgup {
  position: relative;
  bottom: 20px;
}

.imgups {
  position: relative;
  top: 20px;
}

.videoalls {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.videoall {
  width: 100%;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  box-sizing: border-box;
  color: #747575;
}
</style>